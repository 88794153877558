<template>
    <div>
        <div class="mb-2 flex flex-row items-center space-x-1.5">
            <h3 class="text-base font-semibold">Group size &amp; budget</h3>
            <a
                v-if="current"
                href="#"
                @click.prevent="() => applyFilter('budget', null)"
                class="text-xs"
                >clear</a
            >
        </div>

        <div class="flex flex-col gap-y-2 text-sm">
            <div class="flex flex-col gap-y-1">
                <label for="grid-filter-budget-group_size"
                    >Approximately how many guests</label
                >

                <div class="flex flex-row items-center space-x-2">
                    <input
                        type="number"
                        name="group_size"
                        min="1"
                        id="grid-filter-budget-group_size"
                        class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                        placeholder="Add your group size"
                        v-model="groupSize"
                    />
                    <button
                        @click="applyFilters"
                        type="button"
                        class="rounded border border-gray-300 bg-white px-2 py-2 hover:bg-gray-100/90"
                        data-testid="modal-filters-budget-apply-participants-button"
                    >
                        Apply
                    </button>
                </div>
            </div>

            <div class="flex flex-col gap-y-1">
                <label>Price per person</label>

                <div class="flex flex-row items-center space-x-2">
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="min_price"
                            :min="minPricePerPerson"
                            id="grid-filter-budget-min"
                            class="block w-full rounded-md border-gray-300 pb-2 pl-6 pt-5 text-base font-normal at768:text-sm"
                            v-model="budget[0]"
                            @blur="validateMinPriceInput"
                        />
                        <label
                            for="grid-filter-budget-min"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Min price</label
                        >
                    </span>
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="max_price"
                            :min="budget[0]"
                            :max="maxPricePerPerson"
                            id="grid-filter-budget-max"
                            class="block w-full rounded-md border-gray-300 pb-2 pl-6 pt-5 text-base font-normal at768:text-sm"
                            placeholder="Max price"
                            v-model="budget[1]"
                            @blur="validateMaxPriceInput"
                        />
                        <label
                            for="grid-filter-budget-max"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Max price</label
                        >
                    </span>
                    <button
                        @click="applyFilters"
                        type="button"
                        class="rounded border border-gray-300 bg-white px-2 py-2 hover:bg-gray-100/90"
                        data-testid="modal-filters-budget-apply-price-button"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetFilter } from '@/composables/useBudgetFilter';
import { useCollectionFilters } from '@/composables/useCollectionFilters';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const {
    groupSize,
    budget,
    validateGroupSizeInput,
    validateMinPriceInput,
    validateMaxPriceInput,
    minPricePerPerson,
    maxPricePerPerson,
} = useBudgetFilter(
    props.current ? parseInt(props.current[0]) || null : null,
    props.current ? [props.current[1], props.current[2]] : null
);

function applyFilters() {
    validateGroupSizeInput();
    validateMinPriceInput();
    validateMaxPriceInput();
    applyFilter('budget', [groupSize.value, ...budget.value]);
}
</script>

<style scoped>
label {
    @apply font-medium;
}
span.price-input-wrapper:before {
    content: '$';
    position: absolute;
    top: 43%;
    left: 10px;
    z-index: 1;
    @apply font-medium;
}
</style>
